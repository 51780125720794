
import axios from 'axios';
import config from '../../config/config';
import { tokenExpired } from '@/assets/js/utils';
const apiEndpoint = config.API_ENDPOINT;

const state = {
    access_token: '',
    user_role: '',
    userId: '',
    user: {},
    registeringdEmail: '',
    redirectPath: null,
    redirecRole: null,
    rememberMe: false,
    rememberEmail: '',
    rememberPassword: '',
};

const getters = {
    getAccessToken: (state) => state.access_token,
    getUserRole: (state) => state.user_role,
    getUserId: (state) => state.userId,
    getUser: (state) => state.user,
    getRegisteringEmail: (state) => state.registeringdEmail,
    getRedirectPath: (state) => state.redirectPath,
    getRedirectRole: (state) => state.redirecRole,
    getRememberMe: (state) => state.rememberMe,
    getRememberEmail: (state) => state.rememberEmail,
    getRememberPassword: (state) => state.rememberPassword,
};

const actions = {
    async setRememberPassword({ commit }, rememberPassword) {
        commit('setRememberPassword', rememberPassword);
    },
    async setRememberEmail({ commit }, rememberEmail) {
        commit('setRememberEmail', rememberEmail);
    },
    async setRememberMe({ commit }, rememberMe) {
        commit('setRememberMe', rememberMe);
    },
    async setAccessToken({ commit }, token) {
        commit('setAccessToken', token);
    },
    async setUserRole({ commit }, user_role) {
        commit('setUserRole', user_role);
    },
    async setUserId({ commit }, userId) {
        commit('setUserId', userId);
    },
    async setUser({ commit }, user) {
        commit('setUser', user);
    },
    async setRegisteringEmail({ commit }, registeringdEmail) {
        commit('setRegisteringEmail', registeringdEmail);
    },
    async setRedirectPath({ commit }, redirectPath) {
        commit('setRedirectPath', redirectPath);
    },
    async setRedirectRole({ commit }, redirectRole) {
        commit('setRedirectRole', redirectRole);
    },
    async reSendOtp({ rootGetters }, body) {
        const formData = new FormData();
        formData.append('email', body.email);
        formData.append('verification_type', body.type);

        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/resend-otp', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async loginAsGuest({commit, rootGetters}) {
        const formData = new FormData();
        formData.append('email', 'abc');

        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/login-as-guest', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            commit('setUserRole', 4);
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async updateSocialLinks({ commit, rootGetters}, body) {
        const formData = new FormData();
        formData.append('social_links', JSON.stringify(body.social_links));
        formData.append('user_id', rootGetters['user/getUserId']);
        console.log(body.social_links);

        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/update-social-links', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            commit('setUser', response.data.data);
            console.log(response.data.data)
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
};

const mutations = {
    setRememberPassword: (state, rememberPassword) => (state.rememberPassword = rememberPassword),
    setRememberEmail: (state, rememberEmail) => (state.rememberEmail = rememberEmail),
    setRememberMe: (state, rememberMe) => (state.rememberMe = rememberMe),
    setAccessToken: (state, access_token) => (state.access_token = access_token),
    setUserRole: (state, user_role) => {
        return state.user_role = user_role;
    },
    setUserId: (state, userId) => {
        return state.userId = userId;
    },
    setUser: (state, user) => {
        return state.user = user;
    },
    setRegisteringEmail: (state, registeringdEmail) => {
        return state.registeringdEmail = registeringdEmail;
    },
    setRedirectPath: (state, redirectPath) => {
        return state.redirectPath = redirectPath;
    },
    setRedirectRole: (state, redirectRole) => {
        return state.redirectRole = redirectRole;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
