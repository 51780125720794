import axios from 'axios';
import config from '../../config/config';
import { tokenExpired } from '@/assets/js/utils';
const apiEndpoint = config.API_ENDPOINT;

const state = {
};

const getters = {
};

const actions = {
    async connectStripeAccount({ rootGetters }) {
        const formData = new FormData();
        formData.append('user_id', rootGetters['user/getUserId']);
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/connect-stripe-account', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async activateStripeAccount({ rootGetters }) {
        const formData = new FormData();
        formData.append('user_id', rootGetters['user/getUserId']);
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/active-stripe-account', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async createPaymentLink({ rootGetters }, body) {
        const formData = new FormData();
        formData.append('host_id', body.hostId);
        formData.append('amount', body.amount);
        formData.append('attendee_id', rootGetters['user/getUserId']);
        formData.append('attendee_name', rootGetters['user/getUser'].name);
        formData.append('attendee_type', rootGetters['user/getUserRole']);
        formData.append('event_id', body.eventId);
        console.log(rootGetters['user/getUserId'])
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/create-payment-link', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
};

const mutations = {

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
