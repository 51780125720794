<template>
    <div class="modal fade" id="ratingModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header justify-content-between">
                    <h1 class="modal-title fs-5 text-neutral-25 font-weight-600" id="exampleModalLabel">RATE HOST</h1>
                    <button type="button " class="bg-neutral-800 border-0" data-bs-dismiss="modal" aria-label="Close"><i
                            class="fa-solid fa-times text-neutral-25"></i></button>
                </div>
                <form id="ratingForm" ref="form" @submit.prevent="handleRate" novalidate>

                    <div class="modal-body bg-dark">
                        <div class="rounded bg-neutral-800  pb-2">
                            <div>
                                <img class="host-cover-image object-fit-cover position-relative pt-2 ps-2 pe-2 rounded"
                                    :src="host?.cover_photo_path || require('@/assets/util/images/cover.jpg')" alt="">
                                <div class="profile">
                                    <img class="host-profile-image rounded-circle position-absolute"
                                        :src="host?.profile_image_path || require('@/assets/util/images/avatar.png')"
                                        alt="">
                                </div>
                            </div>

                        </div>
                        <div class="d-flex justify-content-center mt-5">
                            <div>
                                <button type="button" class="border-0 bg-neutral-800" @click="starClickHandler(1)"><i
                                        :class="[ratingNumber >= 1 ? 'fa-solid' : 'fa-regular', 'fa-star rating text-24 ']"></i></button>
                                <button type="button" class="border-0 bg-neutral-800" @click="starClickHandler(2)"><i
                                        :class="[ratingNumber >= 2 ? 'fa-solid' : 'fa-regular', 'fa-star rating text-24']"></i></button>
                                <button type="button" class="border-0 bg-neutral-800" @click="starClickHandler(3)"><i
                                        :class="[ratingNumber >= 3 ? 'fa-solid' : 'fa-regular', 'fa-star rating text-24']"></i></button>
                                <button type="button" class="border-0 bg-neutral-800" @click="starClickHandler(4)"><i
                                        :class="[ratingNumber >= 4 ? 'fa-solid' : 'fa-regular', 'fa-star rating text-24']"></i></button>
                                <button type="button" class="border-0 bg-neutral-800" @click="starClickHandler(5)"><i
                                        :class="[ratingNumber >= 5 ? 'fa-solid' : 'fa-regular', 'fa-star rating text-24']"></i></button>
                            </div>
                        </div>
                        <!-- <input type="text" v-model="feedBack"> -->
                        <div class="mt-3">
                            <p class="text-neutral-25">Review</p>
                            <textarea class="review-text mt-2" placeholder="Your Review" v-model="feedBack"></textarea>
                        </div>
                        <div>
                            <input type="hidden" v-model="ratingNumber" required>
                        </div>
                        <div class="server_side_errors"></div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary ps-5 pe-5 pt-2 pb-2">Rate</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { closeModal, formValidation, serverSideErrorsHtml } from '@/assets/js/utils';
import { useToast } from 'vue-toastification';

export default ({
    props: {
        hostId: String,
        host: Object
    },
    setup(props) {
        const toast = useToast();
        const store = useStore();
        const ratingNumber = ref('');
        const feedBack = ref('');

        const starClickHandler = num => {
            ratingNumber.value = num;
        }

        const handleRate = async () => {
            const isValid = formValidation(document.getElementById('ratingForm'));
            if (isValid) {
                const body = {
                    ratingNumber: ratingNumber.value,
                    feedBack: feedBack.value,
                    hostId: props.hostId
                }
                const response = await store.dispatch('attendeeEvent/storeHostRating', body);
                if (response.data.status) {
                    closeModal('ratingModal');
                    toast.success(response.data.msg);
                    ratingNumber.value = '';
                    feedBack.value = '';
                } else {
                    if (response.status == 422) {
                        serverSideErrorsHtml(response, 'ratingForm')
                    } else {
                        toast.error(response.data.msg);
                    }
                }
            }
        }

        return {
            ratingNumber,
            starClickHandler,
            feedBack,
            handleRate,
        }
    }
})
</script>
<style scoped>
.review-text {
    width: 100%;
    background-color: #333334;
    border: 1px solid #A4A4A5;
    border-radius: 4px;
    height: 100px;
    padding-left: 10px;
    padding-top: 10px;
    color: white;
}

.review-text:focus {
    outline: none;
    border: 1px solid #A4A4A5;
}

.rating {
    color: rgba(247, 144, 9, 1);
}

.host-cover-image {
    width: 100%;
    border-radius: 10px;
}

.profile {
    display: flex;
    align-items: center;
    justify-content: center;
}

.host-cover-image {
    height: 120px;
}

.host-profile-image {
    width: 80px;
    height: 80px;
}

.modal-content {
    background-color: #242425;
}

.modal-footer {
    border-top: none !important;
}
</style>
