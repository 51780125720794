<template>
    <div class="modal fade" id="TipModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header pb-0">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">TIP THE HOST</h1>
                    <button type="button" class="bg-neutral-800 border-0" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fa-solid fa-times text-neutral-25"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-3 d-flex align-items-center gap-2 fw-bold">
                        <img width="36" height="36" class="object-fit-cover rounded-circle"
                            :src="host.profile_image_path || require('@/assets/util/images/avatar.png')" alt="">
                        <p>{{ host.display_name }}</p>
                    </div>
                    <div>
                        <label for="exampleInputEmail1" class="form-label">Select an amount and tip the host</label>
                        <div class="">
                            <div class="form-check">
                                <input class="form-check-input cursor-pointer" @change="handleChangeAmount" type="radio"
                                    v-model="amount" value=1 id="amount1">
                                <label class="form-check-label cursor-pointer ps-1 text-white pt-0"
                                    for="amount1">$1</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input cursor-pointer" @change="handleChangeAmount" type="radio"
                                    v-model="amount" value=2 id="amount2">
                                <label class="form-check-label cursor-pointer ps-1 text-white pt-0"
                                    for="amount2">$2</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input cursor-pointer" @change="handleChangeAmount" type="radio"
                                    v-model="amount" value=5 id="amount3">
                                <label class="form-check-label cursor-pointer ps-1 text-white pt-0"
                                    for="amount3">$5</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input cursor-pointer" @change="handleChangeAmount" type="radio"
                                    v-model="amount" value="custom" id="amount_custom">
                                <label class="form-check-label cursor-pointer ps-1 text-white pt-0"
                                    for="amount_custom">Custom</label>
                            </div>
                            <input type="number" v-if="amount == 'custom'" @input="handleChangeAmount"
                                class="form-control text-white tip_amount_input" v-model="customAmount"
                                placeholder="Enter amount">
                                <div>
                                    <p v-if="!validAmount" class="text-danger">{{ invalidAmountMessage }}</p>
                                </div>
                            <div class="server_side_errors"></div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex justify-content-center">
                    <button :disabled="btnDisable || btnLoading" type="button" :class="[btnLoading ? 'btn_loading' : '', 'btn btn-primary']" @click="processPayment">PAY
                        NOW</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { serverSideErrorsHtml } from '@/assets/js/utils';
import { useRoute } from 'vue-router';


export default {
    props: {
        hostId: String,
        host: Object,
    },
    setup(props) {
        const store = useStore();
        const toast = useToast();
        const amount = ref('');
        const customAmount = ref('');
        const btnDisable = ref(true);
        const invalidAmountMessage = ref('');
        const validAmount = ref(true);
        const route = useRoute();
        const btnLoading = ref(false);

        const processPayment = async () => {
            btnLoading.value = true;
            if (!btnDisable.value) {
                const body = {
                    hostId: props.hostId,
                    eventId: route.params.eventId,
                    amount: amount.value == 'custom' ? customAmount.value : amount.value,
                };
                const response = await store.dispatch('stripe/createPaymentLink', body);
                if (response.data.status) {
                    window.location.href = response.data.url;
                    btnLoading.value = false;
                } else {
                    if (response.status == 422) {
                        serverSideErrorsHtml(response, 'TipModal')
                        btnLoading.value = false;
                    } else {
                        toast.error(response.data.msg);
                        btnLoading.value = false;
                    }
                }
            }
        };

        const handleChangeAmount = () => {
            if (amount.value == 'custom') {
                if (customAmount.value < 1) {
                    invalidAmountMessage.value = 'Amount must be greater than $1.';
                    validAmount.value = false;
                    btnDisable.value = true;
                } else if(customAmount.value > 100) {
                    invalidAmountMessage.value = 'Amount must not be greater than $100.';
                    validAmount.value = false;
                    btnDisable.value = true;
                } else if((parseFloat(customAmount.value) * 100 ) % 1 !== 0) {
                    invalidAmountMessage.value = 'Amount must not be greater two decimal place.';
                    validAmount.value = false;
                    btnDisable.value = true;
                } else {
                    validAmount.value = true;
                    btnDisable.value = false;
                }
            } else if (amount.value > 0) {
                btnDisable.value = false;
            }
        }

        return {
            processPayment,
            amount,
            customAmount,
            handleChangeAmount,
            btnDisable,
            validAmount,
            invalidAmountMessage,
            btnLoading
        };
    },
};
</script>

<style>
.tip_amount_input::placeholder {
    color: #d9d9d9 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
