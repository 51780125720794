<template>
    <template v-if="userLoading">
        <LoadingComponent />
    </template>
    <template v-else>
        <h1 class="text-white">Settings</h1>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active tab-button" id="profile-tab" data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane"
                    aria-selected="true">Profile</button>
            </li>
            <template v-if="role == 2">
                <li class="nav-item" role="presentation">
                    <button class="nav-link tab-button" id="wallet-tab" data-bs-toggle="tab"
                        data-bs-target="#wallet-tab-pane" type="button" role="tab" aria-controls="wallet-tab-pane"
                        aria-selected="false">Wallet</button>
                </li>
            </template>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                tabindex="0">
                <ProfileTab :profileInfo="profileInfo" />
            </div>
            <template v-if="role == 2">
                <div class="tab-pane fade" id="wallet-tab-pane" role="tabpanel" aria-labelledby="wallet-tab"
                    tabindex="0">
                    <WalletTab :profileInfo="profileInfo" />
                </div>
            </template>
            <template v-else>
                <div class="tab-pane fade" id="wallet-tab-pane" role="tabpanel" aria-labelledby="wallet-tab"
                    tabindex="0">
                    <AttendeeWalletTab :profileInfo="profileInfo" />
                </div>
            </template>
        </div>
    </template>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import ProfileTab from '@/components/Host/Profile/Edit/Tabs/ProfileTab.vue';
import WalletTab from '@/components/Host/Profile/Edit/Tabs/WalletTab.vue';
import AttendeeWalletTab from '@/components/Host/Profile/Edit/Tabs/AttendeeWalletTab.vue';
import LoadingComponent from '@/components/Shared/LoadingComponent.vue';
export default ({
    components: {
        ProfileTab,
        WalletTab,
        AttendeeWalletTab,
        LoadingComponent,
    },
    setup() {
        const store = useStore();
        const role = ref(store.getters['user/getUserRole']);
        const profileInfo = ref({});
        const userLoading = ref(true);
        onMounted(async () => {
            await fetchUserDetails();
        });

        const fetchUserDetails = async () => {
            try {
                userLoading.value = true;
                await store.dispatch('hostProfile/fetchUserDetails');
                profileInfo.value = store.getters['hostProfile/getProfileInfoData'];
                userLoading.value = false;
            } catch (error) {
                console.error(error);
            }
        };
        return {
            profileInfo,
            role,
            userLoading
        }
    }
})
</script>
<style scoped>
#myTab {
    border-bottom: none !important;
}

.tab-button {
    /* background-color: none !important;
    border:none !important;
    background: none !important;
    color: white !important; */
    color: #D1D1D1 !important;
    border: none !important;
}

.tab-button:hover {
    /* border: 1px solid #171718 !important; */
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #AC53F2 !important;
    background: none !important;
    border: none !important;
    border-bottom: 1px solid #AC53F2 !important;
}

.nav-link {
    border-bottom: 1px solid #FCFCFC !important;
}
</style>
