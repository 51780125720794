// router.js
import { createRouter, createWebHistory } from 'vue-router';
import isAuthenticated from './components/Helpers/User.js';
import LayoutComponent from './components/Layouts/LayoutComponent.vue';
import AuthLayout from './components/Auth/Layout/AuthLayout.vue';
import LoginComponent from './components/Auth/Components/LoginComponent.vue';
import RegisterComponent from './components/Auth/Components/RegisterComponent.vue';
import VerificationComponent from './components/Auth/Components/VerificationComponent.vue';
import ForgotPasswordComponent from './components/Auth/Components/ForgotPasswordComponent.vue';
import EmailVerification from './components/Auth/Components/EmailVerification.vue';
import store from '@/store/index.js';
import authMiddleware from '@/middleware/authMiddleware.js';

/* Host Component */
import DashboardComponent from '@/components/Host/Dashboard/DashboardComponent.vue';
import EventComponent from '@/components/Host/Event/EventComponent.vue';
import EventDetails from '@/components/Host/Event/EventDetails.vue';
import TestComponent from './components/Test/TestComponent.vue';
import ProfilePage from '@/components/Host/Profile/ProfilePage.vue'
import ProfileEdit from '@/components/Host/Profile/Edit/ProfileEdit.vue'

/* Attendee Component */
import AttendeeDashboardComponent from '@/components/Attendee/Dashboard/DashboardComponent.vue';
import AttendeeProfilePage from '@/components/Attendee/Profile/ProfilePage.vue';
import AttendeeEventDetails from '@/components/Attendee/Event/EventDetails.vue';
import HostDetails from './components/Attendee/Profile/HostDetails.vue';

import NotFound from '@/components/Shared/NotFound.vue';
import StripeConnectSuccess from './components/Host/Stripe/StripeConnectSuccess.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: () => {
                const token = store.getters['user/getUserRole'];
                if (token) {
                    if (store.getters['user/getUserRole'] == 2) {
                        return '/host/dashboard';
                    } else {
                        return '/attendee/dashboard';
                    }
                } else {
                    return '/auth/login';
                }
            }
        },
        {
            path: '/host',
            component: LayoutComponent,
            beforeEnter: (to, from, next) => authMiddleware(to, from, next, 2),
            children: [
                {
                    path: 'dashboard',
                    name: 'host.dashboard.index',
                    component: DashboardComponent,
                    meta: { title: 'Home'},
                    props: true,
                },
                {
                    path: 'event',
                    name: 'host.event.index',
                    component: EventComponent,
                    meta: { title: 'Event' },
                },
                {
                    path: 'event/:eventId',
                    name: 'host.event.details',
                    component: EventDetails,
                    meta: { title: 'Event Details' }
                },
                {
                    path: 'test',
                    name: 'test',
                    component: TestComponent,
                    meta: { title: 'Test' }
                },
                {
                    path: 'profile',
                    name: 'host.profile.index',
                    component: ProfilePage,
                    meta: { title: 'Profile' }
                },
                {
                    path: 'profile/edit',
                    name: 'host.profile.edit',
                    component: ProfileEdit,
                    meta: { title: 'Profile' }
                },
                {
                    path: 'stripe/connect/success',
                    component: StripeConnectSuccess,
                    meta: { title: 'Stripe Connect Success' }
                },
            ]
        },
        {
            path: '/attendee',
            component: LayoutComponent,
            beforeEnter: (to, from, next) => authMiddleware(to, from, next, 3),
            children: [
                {
                    path: 'dashboard',
                    name: 'attendee.dashboard.index',
                    component: AttendeeDashboardComponent,
                    meta: { title: 'Home' },
                    // meta: { title: 'Home' , requiresAuth: true}
                    props: true
                },
                {
                    path: 'profile',
                    name: 'attendee.profile.index',
                    component: AttendeeProfilePage,
                    meta: { title: 'Profile' },
                },
                {
                    path: 'profile/edit',
                    name: 'attendee.profile.edit',
                    component: ProfileEdit,
                    meta: { title: 'Profile' }
                },
                {
                    path: 'event/:eventId',
                    name: 'attendee.event.details',
                    component: AttendeeEventDetails,
                    meta: { title: 'Event Details' }
                },
                {
                    path: 'host-profile/:hostId',
                    name: 'profile.details',
                    component: HostDetails,
                    meta: { title: 'Profile' }
                },
            ]
        },
        {
            path: '/auth',
            component: AuthLayout,
            children: [
                {
                    path: 'login',
                    name: 'login',
                    component: LoginComponent,
                    meta: { title: 'Login' }
                },
                {
                    path: 'register',
                    name: 'register',
                    component: RegisterComponent,
                    meta: { title: 'Register' }
                },
                {
                    path: 'reset-password',
                    name: 'reset.password',
                    component: ForgotPasswordComponent,
                    meta: { title: 'Forgot Password' }
                },
                {
                    path: 'verification',
                    name: 'verification',
                    component: VerificationComponent,
                    meta: { title: 'Verification' },
                    props: true
                },
                {
                    path: 'verification/email',
                    name: 'auth.email.verification',
                    component: EmailVerification,
                    meta: { title: 'Verification' },
                    props: true
                },
            ]
        },
        {
            path: '/:catchAll(.*)',
            name: 'not.found',
            component: NotFound,
        }
    ]
});

router.beforeEach((to, from, next) => {
    const baseTitle = ' - CrowdQ';
    document.title = (to.meta.title || 'Home') + baseTitle;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const isLoggedin = isAuthenticated.hasToken();
        if (!isLoggedin) {
            next('auth/login');
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
