<template>
    <div class="mt-4">
        <h2 class="text-24 text-neutral-25 mb-0">Wallet</h2>
        <p class="text-neutral-100 text-3">Add wallet for tips or Membership purchase, also withdraw your earnings</p>
    </div>
    <div class="container p-0 mt-4">
        <div class="row">
            <div class="left col-12 col-sm-12 col-md-4 pb-3">
                <div class="bg-neutral-800 ps-3 pt-3 pe-3 rounded dashboard-card">
                    <div class="d-flex gap-2">
                        <div class="card-icon bg-neutral-900 ps-3 pe-3 pt-2 rounded  text-primary"><i
                                class="fa-solid fa-wallet"></i></div>
                        <div>
                            <h6 class="text-neutral-25 text-20 font-weight-600">PAYMENT METHODS</h6>
                            <p class="text-neutral-400 text-3">Manage your payment method</p>
                        </div>
                    </div>

                    <button :disabled="btnLoading" v-if="user.stripe_account_status != 'Active'"
                        :class="[btnLoading ? 'btn_loading' : '', 'btn btn-primary w-100 mt-4 mb-3']"
                        @click="connectStripeHandler">CONNECT
                        STRIPE</button>
                    <button :disabled="true" v-if="user.stripe_account_id && user.stripe_account_status == 'Active'"
                        :class="['btn bg-success-500 text-white border-0 w-100 mt-4 mb-3']">CONNECTED</button>
                </div>
            </div>

        </div>
    </div>

</template>
<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

export default ({
    props: {
        profileInfo: Object
    },
    setup(props) {
        const store = useStore();
        const user = computed(() => store.getters['user/getUser']);
        const toast = useToast();
        const walletForm = ref({
            stripePublicKey: props.profileInfo.stripe_public_key,
            stripeSecretKey: props.profileInfo.stripe_secret_key
        });
        const btnLoading = ref(false);

        watch(() => props.profileInfo, (newProfileInfo) => {
            walletForm.value.stripePublicKey = newProfileInfo.stripe_public_key;
            walletForm.value.stripeSecretKey = newProfileInfo.stripe_secret_key;
        });

        const saveWalletInfo = async () => {
            await store.dispatch('hostProfile/updateHostProfileWallet', walletForm.value);
        }

        const connectStripeHandler = async () => {
            btnLoading.value = true;
            const response = await store.dispatch('stripe/connectStripeAccount');
            if (response.data.status) {
                btnLoading.value = false;
                window.location.href = response.data.url;
            } else {
                btnLoading.value = false;
                toast.error(response.data.message);
            }
        }

        return {
            walletForm,
            saveWalletInfo,
            connectStripeHandler,
            btnLoading,
            user
        }
    }
})
</script>
<style scoped>
.form-label {
    margin-bottom: 5px;
}

input {
    width: 100%;
    height: 44px;
    padding-left: 10px;
    padding-right: 10px;
    color: #E4E4E5;
    border: 1px solid #A4A4A5;
    background-color: #333334;
    border-radius: 4px;
}

input:focus {
    color: #E4E4E5;
    border: 1px solid #A4A4A5;
    background-color: #333334;
    border-radius: 4px;
    outline: none;
}

.history {
    width: 100%;
    height: 100%;
}
</style>
