<template>
    <div class="modal fade" id="qrcodeModal" tabindex="-1" aria-labelledby="qrcodeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg ">
            <div class="modal-content bg-neutral-800">
                <div class="modal-header justify-content-between">
                    <h1 class="modal-title fs-5 font-weight-600 text-neutral-25" id="qrcodeModalLabel">Share {{ type ==
                        'event' ? 'Event' : type == 'profile' ? 'Profile' : '' }}
                    </h1>
                    <button type="button " class="bg-neutral-800 border-0" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fa-solid fa-times text-neutral-25"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div
                        class="d-flex flex-column flex-lg-row justify-content-around pt-3 gap-3 text-neutral-25 pb-4 modal-content-wrapper">
                        <div class="text-neutral-25 ">
                            <h5 class="text-20">QR CODE </h5>
                            <div class="d-flex justify-content-center">
                                <img class="qr-code bg-white mt-2" :src="src" alt="">
                            </div>
                            <div class="mt-3 d-flex justify-content-between">
                                <button @click="copyQrCode" class="bg-neutral-700 text-neutral-25 rounded p-3 border-0">
                                    <i class="fas fa-copy copy-icon"></i> Copy</button>
                                <button @click="downloadQrCode"
                                    class="bg-secondary text-neutral-25 rounded p-3 border-0">
                                    <i class="fas fa-download download-icon"></i>
                                    Download</button>
                            </div>
                        </div>
                        <template v-if="type == 'event'">
                            <div
                                class="d-flex flex-row flex-lg-column align-items-center text-neutral-25 border-content">
                                <span class="border-separator"></span>
                                <span class="qr_modal_or">Or</span>
                                <span class="border-separator"></span>
                            </div>
                            <div class="d-flex justify-content-center align-items-center flex-column text-neutral-25">
                                <h5 class="text-25">{{ type == 'event' ? 'Event' : type == 'profile' ? 'Profile' : '' }}
                                    CODE</h5>
                                <div class="d-flex mt-2">
                                    <h4
                                        class=" profile-code text-20 border-neutral-700 mb-0 pt-2 ps-4 pb-2 pe-4 fw-bold rounded-start">
                                        {{ code }}</h4>
                                    <button @click="profileCode"
                                        class="bg-neutral-700 text-neutral-25 border-0 pt-2 ps-4 pb-2 pe-4 rounded-end">Copy</button>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from 'vue-toastification';

export default ({
    props: {
        event: Object,
        src: String,
        code: String,
        basePath: String,
        type: String,
        url: String
    },
    setup(props) {
        const toast = useToast();

        const profileCode = () => {
            navigator.clipboard.writeText(props.code)
                .then(() => {
                    toast.success('Copy success');
                })
        };
        const copyQrCode = () => {
            console.log(navigator)
            navigator.clipboard.writeText(props.url)
                .then(() => {
                    toast.success('Copy success');
                })
        }

        const downloadQrCode = () => {
            const base64SVG = props.basePath;

            // Step 2: Create a new Image element
            var img = new Image();
            img.onload = function () {
                // Step 3: Draw the Image on a Canvas
                var canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                // Step 4: Convert Canvas to PNG
                var dataURL = canvas.toDataURL('image/png');

                // Step 5: Download the PNG
                var link = document.createElement('a');
                link.download = 'image.png';
                link.href = dataURL;
                link.click();
            };
            img.src = 'data:image/svg+xml;base64,' + base64SVG;
        };

        return {
            profileCode,
            copyQrCode,
            downloadQrCode,
        }
    }
})
</script>
<style scoped>
.border-separator {
    width: 1px;
    height: 50%;
    background-color: #747475;
}
.qr-code {
    width: 244px;
    height: 244px;
}
</style>
