<template>
  <template v-if="userLoading">
    <LoadingComponent />
  </template>
  <template v-else>
    <RatingComponent :hostId="hostId" :host="user" />
    <TipModal :hostId="hostId" :host="user" />
    <div class="position-relative">
      <img
        class="cover-image position-relative rounded"
        :src="user.cover_photo_path || require('@/assets/util/images/cover.jpg')"
        alt=""
      />
      <img
        class="profile-image rounded-circle position-absolute bottom-0 start-50 object-fit-cover"
        :src="user.profile_image_path || require('@/assets/util/images/avatar.png')"
        alt=""
      />
    </div>
    <div class="profile-short-info-card mt-3">
      <div class="rating-container">
        <div class="rating-cards gap-3">
          <div class="d-flex">
            <div class="bg-neutral-800 d-flex align-items-center tips gap-3 rounded-pill">
              <span class="tips-icon bg-neutral-900 rounded-circle"
                ><i class="fa-solid fa-star rating-icon"></i
              ></span>
              <span>
                <p class="text-neutral-200 text-3">Rating</p>
                <h6 class="text-neutral-25 text-4">
                  {{ user.rating }}
                  <span class="number_of_rating">({{ user.total_review }})</span>
                </h6>
              </span>
            </div>
            <button
              v-if="!isRated"
              v-on:click="role == 4 ? loginAlert.loginAlert() : openModal('ratingModal')"
              class="rounded share-qr btn-primary font-weight-bold ms-2 text-neutral-25 font-weight-600 h-fit-content"
            >
              <i class="fa-solid fa-star"></i>
              <span class="ms-2 d-none d-lg-inline-block">RATE</span>
            </button>
          </div>
        </div>
      </div>
      <div class="profile__short__info--right">
        <div>
          <button
            @click="openModal('TipModal')"
            class="rounded share-qr btn-primary font-weight-bold ms-2 text-neutral-25 font-weight-600"
          >
            <i class="fa-solid fa-dollar-sign"></i>
            <span class="ms-2 d-none d-lg-inline-block">SEND TIP</span>
          </button>
          <button
            @click="openModal('qrcodeModal')"
            class="rounded share-qr bg-neutral-800 font-weight-bold font-weight-600 ms-2 text-neutral-25"
          >
            <i class="fa-solid fa-qrcode"></i>
            <span class="ms-2 d-none d-lg-inline-block">SHARE</span>
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center user_info mb-4">
      <h3 class="text-neutral-25 text-28 font-weight-600">{{ user.display_name }}</h3>
      <p class="text-neutral-300 text-center px-1 px-lg-5">{{ user.bio }}</p>
    </div>

    <!-- Social Links Section -->
    <section class="bg-neutral-800 text-neutral-25 rounded px-3 py-3 row">
      <div class="position-relative d-flex justify-content-center">
        <h3 class="text-neutral-25 text-20">Social Links</h3>
      </div>
      <div class="row justify-content-center">
        <div class="d-flex justify-content-center gap-3 col-12 col-md-10 flex-wrap">
          <template v-if="user.social_links?.length">
            <a
              target="_blank"
              class="d-flex align-items-center gap-2 bg-neutral-900 px-3 rounded-pill border py-1 text-neutral-25"
              :href="social_link?.link"
              v-for="social_link in user?.social_links"
              :key="social_link.id"
            >
              <img
                class="profile_page_social_link_logo"
                :src="social_link?.logo"
                :alt="social_link?.name"
              />
              {{ social_link?.name }}
            </a>
          </template>
          <p v-if="!user.social_links?.length">
            There is no social link available for this host.
          </p>
        </div>
      </div>
    </section>

    <div class="mt-5" >
      <div class="d-flex justify-content-between">
        <h3 class="text-neutral-25 font-weight-600 text-20">Upcoming Events</h3>
        <!-- <button class=" see-all-btn btn-primary ps-4 pe-4 rounded">SEE ALL <i
                        class="fa-solid fa-arrow-right ms-2"></i></button> -->
      </div>
      <div class="mt-3 cards row">
        <router-link
          :to="{ name: 'attendee.event.details', params: { eventId: event.id } }"
          v-for="(event, index) in enrolledEvents"
          :key="index"
          class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 pb-3"
        >
          <EventCard :event="event" />
        </router-link>
      </div>
    </div>
    <QrcodeModal
      :src="user.qrcode_path"
      :code="user.code"
      :basePath="user.qrcode_base_path"
      :url="user.url"
      :type="'profile'"
    />
  </template>

  <LoginAlert ref="loginAlert" />
</template>
<script>
import { onMounted, ref, computed } from "vue";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { openModal } from "@/assets/js/utils";
import RatingComponent from "@/components/Attendee/Event/RatingComponent.vue";
import TipModal from "@/components/Attendee/Event/TipModal.vue";
import QrcodeModal from "@/components/Host/Event/QrcodeModal.vue";
import EventCard from "@/components/Shared/EventCard.vue";
import LoadingComponent from "@/components/Shared/LoadingComponent.vue";
import LoginAlert from '@/components/Shared/LoginAlert.vue';

export default {
  components: {
    RatingComponent,
    TipModal,
    QrcodeModal,
    EventCard,
    LoadingComponent,
    LoginAlert
  },
  setup() {
    const loginAlert = ref(null);
    const route = useRoute();
    const hostId = ref(route.params.hostId);
    const store = useStore();
    const user = ref({});
    let userProfileCode = null;
    let userQrCode = null;
    const toast = useToast();
    const enrolledEvents = ref([]);
    const isRated = computed(() => store.getters["attendeeEvent/getIsRatedData"]);
    const userLoading = ref(true);
    const role = computed(() => store.getters["user/getUserRole"]);

    const profileCode = () => {
      navigator.clipboard.writeText(userProfileCode).then(() => {
        toast.success("Copy success");
      });
    };
    const copyQrCode = () => {
      navigator.clipboard.writeText(userQrCode).then(() => {
        toast.success("Copy success");
      });
    };
    const downloadQrCode = () => {
      const imageUrl = "../../assets/util/images/logo.png";
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "myqr.png";
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    };

    const fetchAttendeeEnrolledEvents = async () => {
      try {
        userLoading.value = true;
        await store.dispatch("event/fetchHostUpcomingEvents", hostId.value);
        enrolledEvents.value = store.getters["event/getHostUpcomingEvents"];
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUserDetails = async () => {
      try {
        await store.dispatch("hostProfile/fetchUserDetails", {
          userId: hostId.value,
          attendeeId: store.getters["user/getUserId"],
        });
        user.value = store.getters["hostProfile/getProfileInfoData"];
        store.commit("attendeeEvent/setIsRatedData", user.value.is_rated);
        userLoading.value = false;
      } catch (error) {
        console.error(error);
        userLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchAttendeeEnrolledEvents();
      await fetchUserDetails();
      const profileCodeElement = document.querySelector(".profile-code");
      if (profileCodeElement) {
        userProfileCode = profileCodeElement.innerText;
      }
      const copyQrCodeElement = "my-profile-url";
      if (copyQrCodeElement) {
        userQrCode = copyQrCodeElement;
      }
    });
    return {
      profileCode,
      copyQrCode,
      downloadQrCode,
      enrolledEvents,
      user,
      openModal,
      hostId,
      isRated,
      userLoading,
      role,
      loginAlert,
    };
  },
};
</script>
<style scoped>
.rating-icon {
  color: rgba(247, 144, 9, 1);
}

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-short-info-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cover-image {
  height: 280px;
  width: 100%;
  object-fit: cover;
}

.profile-image {
  width: 180px;
  height: 180px;
  border: 5px solid #242425;
  transform: translate(-50%, 50%);
}

.tips {
  padding: 5px 15px 5px 10px;
}

.tips-icon {
  padding: 7px 12px 7px 12px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-self: center;
}

.star {
  color: rgba(243, 137, 0, 0.933);
}

.tips-icon i {
  font-size: 18px;
}

.share-qr {
  padding: 10px 15px 10px 15px;
  border: none;
}

.card {
  border: 1px solid #484848 !important;
}

.card-date {
  font-weight: bold;
  border: none;
}

.modal-header {
  border-bottom: none !important;
}

.dropdown-ul {
  right: 38px !important;
}

.dropdown-menu {
  --bs-dropdown-link-hover-bg: none !important;
  --bs-dropdown-link-active-bg: none !important;
}

@media (max-width: 992px) {
  .profile-image {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 1200px) {
  .profile-image {
    width: 120px;
    height: 120px;
  }

  .cover-image {
    height: 180px;
  }
}
</style>
