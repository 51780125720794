<template>
  <div class="bg-neutral-700 rounded mb-3 d-flex" :id="'socialLink_' + id">
    <div class="flex-1 p-2 border-end border-neutral-500">
      <div class="mb-3">
        <label :for="'nameInput' + id" class="form-label">Name</label>
        <select
          class="form-control"
          :id="'nameInput' + id"
          v-model="internalName"
          @change="updateField('social_id', internalName)"
          required
        >
          <option disabled value="">Select an option</option>
          <option v-for="social in socials" :key="social.id" :value="social.id">
            {{ social.name }}
          </option>
        </select>
      </div>
      <div class="mb-3">
        <label :for="'linkInput' + id" class="form-label">Link</label>
        <input
          type="text"
          class="form-control"
          :id="'linkInput' + id"
          v-model="internalLink"
          @input="updateField('link', internalLink)"
          placeholder="Enter link"
          required
        />
      </div>
    </div>
    <div class="p-2">
        <button type="button" @click="removeSocialLink(id)" class="btn text-danger">
          <i class="fa-solid fa-trash-can"></i>
        </button>
      </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    social_id: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    removeSocialLink: Function
  },
  emits: ["update-field"],
  setup(props, { emit }) {
    const store = useStore();
    const internalName = ref(props.social_id);
    const internalLink = ref(props.link);
    const socials = computed(() => store.getters["hostProfile/getSocialList"]);

    const updateField = (field, value) => {
      emit("update-field", { id: props.id, field, value });
    };

    watch(
      () => props.social_id,
      (newName) => {
        internalName.value = newName;
      }
    );

    watch(
      () => props.link,
      (newLink) => {
        internalLink.value = newLink;
      }
    );

    return {
      internalName,
      internalLink,
      updateField,
      socials,
    };
  },
};
</script>

<style scoped>
.form-control::placeholder {
  color: #fcfcfc;
}
</style>
