<template>
    <h3 class="auth_page_titlte text-neutral-100">Verification</h3>
    <p class="text-neutral-200 mb-3">Please enter the code</p>

    <form class="w-100" @submit.prevent="verifyOtp">
        <div v-if="errorMessages.length > 0"
            :class="['alert', 'alert-danger', 'd-flex', 'align-items-center', 'alert-dismissible', {
                'fade': errorMessages
                    .length > 0,
                'show': errorMessages.length > 0
            }]"
            role="alert">
            <i class="fa-solid fa-triangle-exclamation me-2"></i>
            <div>
                <span v-html="errorMessages.join('</br> ')"></span>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div class="d-flex justify-content-evenly gap-2 mb-3">
            <input @input="handleInput($event, 1)" @paste="handlePaste($event)" @keyup="handleKeyPress($event, 1)"
                @focus="handleFocus($event, 1)" id="input1" v-model="form.otp1" type="number" class="otp_input_field form-control text-center"
                maxlength="1">
            <input @input="handleInput($event, 2)" @paste="handlePaste($event)" @keyup="handleKeyPress($event, 2)"
                @focus="handleFocus($event, 2)" id="input2" v-model="form.otp2" type="number" class="otp_input_field form-control text-center"
                maxlength="1">
            <input @input="handleInput($event, 3)" @paste="handlePaste($event)" @keyup="handleKeyPress($event, 3)"
                @focus="handleFocus($event, 3)" id="input3" v-model="form.otp3" type="number" class="otp_input_field form-control text-center"
                maxlength="1">
            <input @input="handleInput($event, 4)" @paste="handlePaste($event)" @keyup="handleKeyPress($event, 4)"
                @focus="handleFocus($event, 4)" id="input4" v-model="form.otp4" type="number" class="otp_input_field form-control text-center"
                maxlength="1">
            <input @input="handleInput($event, 5)" @paste="handlePaste($event)" @keyup="handleKeyPress($event, 5)"
                @focus="handleFocus($event, 5)" id="input5" v-model="form.otp5" type="number" class="otp_input_field form-control text-center"
                maxlength="1">
            <input @input="handleInput($event, 6)" @paste="handlePaste($event)" @keyup="handleKeyPress($event, 6)"
                @focus="handleFocus($event, 6)" id="input6" v-model="form.otp6" type="number" class="otp_input_field form-control text-center"
                maxlength="1">
        </div>
        <div class="form-floating mb-3 position-relative">
            <input :type="showPassword ? 'text' : 'password'" class="form-control" id="password" placeholder="New Password"
                v-model="form.password" required>
            <label for="password">New Password <span class="text-danger">*</span></label>
            <div class="eye-icon-container position-absolute top-0 bottom-0 end-0 d-flex align-items-center">
                <button type="button" class="btn eye-icon-btn" @click="togglePasswordVisibility"><i
                        :class="[showPassword ? 'fa-eye' : 'fa-eye-slash', 'fa-regular']"></i></button>
            </div>
        </div>
        <div class="form-floating mb-3 position-relative">
            <input :type="showPassword ? 'text' : 'password'" class="form-control" id="confirm_password"
                placeholder="Comfirm New Password" v-model="form.password_confirmation" required>
            <label for="confirm_password">Comfirm New Password <span class="text-danger">*</span></label>
            <div class="eye-icon-container position-absolute top-0 bottom-0 end-0 d-flex align-items-center">
                <button type="button" class="btn eye-icon-btn" @click="togglePasswordVisibility"><i
                        :class="[showPassword ? 'fa-eye' : 'fa-eye-slash', 'fa-regular']"></i></button>
            </div>
        </div>
        <button type="submit" class="btn btn-primary w-100">Change Password</button>
    </form>
    <div class="d-flex justify-content-between my-3">
        <p class="text-neutral-100">Didn't receive the code?</p>
        <template v-if="timer">
            <span>{{ minutes }}:{{ seconds < 10 ? '0' + seconds : seconds }}</span>
        </template>
        <template v-else>
            <button type="button" @click="startTimer" class="bg-transparent border-0 text-blue-500">Resend Code</button>
        </template>
    </div>
</template>

<script>
import axios from 'axios';
import { computed, inject, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';
import eventBus from '../../Helpers/EventBus.js';

export default {
    setup() {
        const router = useRouter();
        const globalVariables = inject('globalVariables');
        const toast = useToast();

        const errorMessages = ref([]);
        const previousValue = ref('');
        const showPassword = ref(false);

        const receivedEmail = eventBus.email;

        const timer = ref(10);
        const timerActive = ref(false);
        const minutes = computed(() => Math.floor(timer.value / 60));
        const seconds = computed(() => timer.value % 60);

        const startTimer = () => {
            timer.value = 10;
            timerActive.value = true;
            const interval = setInterval(() => {
                if (timer.value > 0) {
                    timer.value--;
                } else {
                    clearInterval(interval);
                    timerActive.value = false;
                }
            }, 1000);
        };

        const form = ref({
            email:  receivedEmail || null,
            otp1: null,
            otp2: null,
            otp3: null,
            otp4: null,
            otp5: null,
            otp6: null,
            password: null,
            password_confirmation: null
        });

        const verifyOtp = async () => {
            axios.post(globalVariables.baseURL + '/api/auth/verifyOtp', form.value)
            .then(() => {
                router.push({
                    name: 'login',
                });
                toast.success('Password changed successfully!');
            })
            .catch(error => {
                if (error.response && error.response.status === 422) {
                    const errors = error.response.data.errors;
                    errorMessages.value = Object.values(errors).flat();
                } else if (error.response && error.response.status === 401) {
                    const errors = error.response.data;
                    errorMessages.value = Object.values(errors).flat();
                } else {
                    errorMessages.value = [error.response.data.msg];
                }
            });
        };

        const handleKeyPress = (event, num) => {
            const nextInput = document.getElementById(`input${num + 1}`);
            const previousInput = document.getElementById(`input${num - 1}`);
            if (event.key == 'Backspace' && num != 1) {
                if (!previousValue.value) {
                    previousInput.focus();
                } else {
                    previousValue.value = null;
                }
            } else if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(event.key)) {
                if (num != 6) {
                    nextInput.focus();
                } else {
                    previousValue.value = event.target.value;
                }
            }
        };

        const handleFocus = (event) => {
            previousValue.value = event.target.value;
        };

        const handlePaste = (event) => {
            event.preventDefault();
            const pasteData = event.clipboardData.getData('text').trim().substring(0, 6);
            const characters = pasteData.split('');
            form.value.otp1 = characters[0];
            form.value.otp2 = characters[1];
            form.value.otp3 = characters[2];
            form.value.otp4 = characters[3];
            form.value.otp5 = characters[4];
            form.value.otp6 = characters[5];
            document.getElementById('input6').focus();
        };

        const handleInput = (event, num) => {
            if (event.target.value.length > 1) {
                switch (num) {
                    case 1:
                        form.value.otp1 = parseInt(event.target.value[event.target.value.length - 1]);
                        break;
                    case 2:
                        form.value.otp2 = parseInt(event.target.value[event.target.value.length - 1]);
                        break;
                    case 3:
                        form.value.otp3 = parseInt(event.target.value[event.target.value.length - 1]);
                        break;
                    case 4:
                        form.value.otp4 = parseInt(event.target.value[event.target.value.length - 1]);
                        break;
                    case 5:
                        form.value.otp5 = parseInt(event.target.value[event.target.value.length - 1]);
                        break;
                    case 6:
                        form.value.otp6 = parseInt(event.target.value[event.target.value.length - 1]);
                        break;
                    default:
                        break;
                }
            }
        };

        const togglePasswordVisibility = () => {
            showPassword.value = !showPassword.value;
        };


        return {
            verifyOtp,
            showPassword,
            togglePasswordVisibility,
            handleKeyPress,
            handleFocus,
            handlePaste,
            handleInput,
            router,
            globalVariables,
            toast,
            form,
            errorMessages,
            receivedEmail,
            timer,
            timerActive,
            minutes,
            seconds,
            startTimer
        };
    }
};
</script>



<style>
/* Hide the spinner controls for number input */
.otp_input_field[type=number]::-webkit-inner-spin-button,
.otp_input_field[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.otp_input_field[type=number] {
    -moz-appearance: textfield;
}
</style>
