<template>Hello</template>
<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();
        onMounted(() => {
            handleActiveStripeAccount();
        });

        const handleActiveStripeAccount = async() => {
            try {
                const response = await store.dispatch('stripe/activateStripeAccount');
                console.log(response);
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>
