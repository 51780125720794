import store from "@/store";

class AppStorage {
    storeToken(token) {
        store.dispatch('user/setAccessToken', token);
    }
    storeuserID(userID) {
        store.dispatch('user/setUserId', userID);
    }
    storeuserRole(userRole) {
        const role = store.getters['user/getRedirectRole'] ? store.getters['user/getRedirectRole'] : userRole;
        store.dispatch('user/setUserRole', role);
        store.dispatch('user/setRedirectRole', null);
    }
    storeUser(user) {
        store.dispatch('user/setUser', user);
    }

    store(token, userID, userRole, user) {
        this.storeToken(token);
        this.storeuserID(userID);
        this.storeuserRole(userRole);
        this.storeUser(user);
    }

    clear() {
        store.dispatch('user/setAccessToken', null);
    }

    getToken() {
        return store.getters['user/getAccessToken']; // Add return statement
    }

    getUser() {
        return JSON.parse(localStorage.getItem('user')); // Parse JSON string to object
    }
}

const AppStorageInstance = new AppStorage(); // Create an instance of the class

export default AppStorageInstance; // Export the instance, not the class itself
