<template>
    <div class="mb-3">
        <!-- <label for="exampleInputEmail1" class="form-label">Profile Image</label> -->
        <!-- <input type="file" @change="onProfileImageChange"> -->
    </div>
    <div class="position-relative">
        <div class="position-relative">
            <img v-if="!coverpic" class="cover-image rounded object-fit-cover"
                :src="profileInfo.cover_photo_path || require('@/assets/util/images/cover.jpg')" alt="">
            <img v-if="coverpic" class="cover-image rounded object-fit-cover" :src="coverpic" alt="">
            <label class="cover-image-upload rounded position-absolute">
                <input type="file" class="file" @change="onCoverImageChange"><br>
                <span><i class="fas fa-pen"></i></span>
            </label>
        </div>
        <div class="position-absolute profile-image-edit-wrapper">
            <div class="profile-edit d-flex position-relative">
                <img v-if="!proficepic" class="profile-image rounded-circle object-fit-cover"
                    :src="profileInfo.profile_image_path || require('@/assets/util/images/avatar.png')" alt="">
                <img v-if="proficepic" class="profile-image rounded-circle object-fit-cover" :src="proficepic" alt="">
                <!-- <input type="file" @change="onProfileImageChange"> -->
                <label class="profile-image-upload rounded position-absolute">
                    <input type="file" class="file" @change="onProfileImageChange"><br>
                    <span><i class="fas fa-pen"></i></span>
                </label>
            </div>
        </div>
    </div>

    <div class="input-wrapper">
        <div
            class="text-neutral-100 d-flex flex-column align-items-center justify-content-center profile-edit-input mb-4">
            <form id="updateProfileForm" @submit.prevent="updateProfileInfo" novalidate>
                <div class="mb-3 input-container  ">
                    <label for="exampleInputEmail1" class="form-label text-neutral-100">Display Name</label> <br />
                    <input type="text  " v-model="profileForm.displayName" required>
                </div>
                <div class="mb-3 input-container">
                    <label for="exampleInputEmail1" class="form-label">Full Name</label> <br>
                    <input type="text " v-model="profileForm.name" required>
                </div>
                <div class="mb-3 input-container">
                    <label for="exampleInputEmail1" class="form-label">Bio</label>
                    <textarea class="form-control" rows="3" v-model="profileForm.bio"></textarea>
                </div>
                <div class="mb-3 input-container">
                    <label for="exampleInputEmail1" class="form-label">Phone</label>
                    <vue-tel-input disabled v-model="profileForm.phone"
                        @country-changed="handleCountryChange"></vue-tel-input>
                </div>
                <div class="mb-3 input-container">
                    <label for="exampleInputEmail1" class="form-label">Email</label>
                    <input type="text " v-model="profileForm.email" readonly>
                </div>
                <div class="multi-input ">
                    <div class="mb-3 date-of-birth">
                        <label for="exampleInputEmail1" class="form-label">Date of birth</label> <br>
                        <input type="date" class="text-neutral-100" v-model="profileForm.dateOfBirth"
                            onfocus="this.showPicker()">
                    </div>
                    <div class="mb-3 gender ">
                        <label for="exampleInputEmail1" class="form-label">Gender</label> <br>
                        <select v-model="profileForm.gender">
                            <option value=''>Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </div>
                </div>
                <div class="mb-3 input-container">
                    <label for="exampleInputEmail1" class="form-label">Address</label>
                    <input type="text " v-model="profileForm.address">
                </div>
                <div class="multi-input">
                    <div class="mb-3 date-of-birth">
                        <label for="exampleInputEmail1" class="form-label">Zip</label>
                        <input type="text " v-model="profileForm.zip">
                    </div>
                    <div class="mb-3 date-of-birth">
                        <label for="exampleInputEmail1" class="form-label">City</label>
                        <input type="text " v-model="profileForm.city">
                    </div>
                </div>
                <div class="multi-input">
                    <div class="mb-3 date-of-birth">
                        <label for="exampleInputEmail1" class="form-label">State</label>
                        <input type="text " v-model="profileForm.state">
                    </div>
                    <div class="mb-3 date-of-birth">
                        <label for="exampleInputEmail1" class="form-label">Country</label>
                        <!-- <input type="text " v-model="profileForm.country"> -->
                        <VueSelect2 v-model="profileForm.country" :options="options" :placeholder="'Select Country'" />
                    </div>
                </div>
                <div class="mb-3 default-login ">
                    <label for="exampleInputEmail1" class="form-label">Default Login</label>
                    <div class="d-flex">
                        <div class="d-flex align-items-center gap-2">
                            <input class="default-check" type="radio" v-model="profileForm.default_login" value="2"
                                id="optionHost" :checked="profileForm.default_login === '2'">
                            <label class="form-check-label" for="optionHost"> Host</label>
                        </div>
                        <div class="d-flex align-items-center gap-2 ps-3">
                            <input class="default-check" type="radio" v-model="profileForm.default_login" value="3"
                                id="optionAttendee" :checked="profileForm.default_login === '3'">
                            <label class="form-check-label" for="optionAttendee"> Attendee</label>
                        </div>
                    </div>
                </div>
                <div class="server_side_errors"></div>
                <div class="d-flex justify-content-center mt-3">
                    <button type="submit" class="px-5 btn btn-primary rounded">Save</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { formValidation, serverSideErrorsHtml } from '@/assets/js/utils';
import { useRouter } from 'vue-router';
// import Select2 from 'vue3-select2-component';
import { countries } from '@/assets/js/country.js';

export default ({
    // components: {
    //     Select2
    // },
    props: {
        profileInfo: Object
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const toast = useToast();
        let coverpic = ref(null);
        let proficepic = ref(null);
        const selectedItem = ref(null);
        const role = ref(store.getters['user/getUserRole']);
        const options = countries;

        const profileForm = ref({
            profileImage: null,
            coverImage: null,
            name: props.profileInfo.name ? props.profileInfo.name : '',
            displayName: props.profileInfo.display_name ? props.profileInfo.display_name : '',
            bio: props.profileInfo.bio ? props.profileInfo.bio : '',
            phone: props.profileInfo.phone ? props.profileInfo.phone : '',
            email: props.profileInfo.email ? props.profileInfo.email : '',
            dateOfBirth: props.profileInfo.date_of_birth ? props.profileInfo.date_of_birth : '',
            gender: props.profileInfo.gender ? props.profileInfo.gender : '',
            address: props.profileInfo.address ? props.profileInfo.address : '',
            zip: props.profileInfo.zip ? props.profileInfo.zip : '',
            city: props.profileInfo.city ? props.profileInfo.city : '',
            state: props.profileInfo.state ? props.profileInfo.state : '',
            country: props.profileInfo.country ? props.profileInfo.country : '',
            default_login: props.profileInfo.default_login ? props.profileInfo.default_login : '',
        });

        watch(() => props.profileInfo, (newProfileInfo) => {
            profileForm.value.name = newProfileInfo.name ? newProfileInfo.name : '';
            profileForm.value.displayName = newProfileInfo.display_name ? newProfileInfo.display_name : '';
            profileForm.value.bio = newProfileInfo.bio ? newProfileInfo.bio : '';
            // profileForm.value.phone = newProfileInfo.phone ? newProfileInfo.phone : '';
            profileForm.value.email = newProfileInfo.email ? newProfileInfo.email : '';
            profileForm.value.dateOfBirth = newProfileInfo.date_of_birth ? newProfileInfo.date_of_birth : '';
            profileForm.value.gender = newProfileInfo.gender ? newProfileInfo.gender : '';
            profileForm.value.address = newProfileInfo.address ? newProfileInfo.address : '';
            profileForm.value.zip = newProfileInfo.zip ? newProfileInfo.zip : '';
            profileForm.value.city = newProfileInfo.city ? newProfileInfo.city : '';
            profileForm.value.state = newProfileInfo.state ? newProfileInfo.state : '';
            profileForm.value.country = newProfileInfo.country ? newProfileInfo.country : '';
            profileForm.value.default_login = newProfileInfo.default_login ? newProfileInfo.default_login : '';
        });

        const onProfileImageChange = (event) => {
            profileForm.value.profileImage = event.target.files[0];
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                proficepic.value = reader.result;
            };
            reader.readAsDataURL(file);
        };
        const onCoverImageChange = (event) => {
            profileForm.value.coverImage = event.target.files[0];
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                coverpic.value = reader.result;
            };
            reader.readAsDataURL(file);
        };

        const updateProfileInfo = async () => {
            const isValid = formValidation(document.getElementById('updateProfileForm'));
            if (isValid) {
                const response = await store.dispatch('hostProfile/updateHostProfile', profileForm.value);
                if (response.data.status) {
                    toast.success(response.data.msg);
                    if (role.value == 2) {
                        router.push({ name: 'host.profile.index' });
                    } else if (role.value == 3) {
                        router.push({ name: 'attendee.profile.index' });
                    }
                } else {
                    if (response.status == 422) {
                        serverSideErrorsHtml(response, 'updateProfileForm')
                    } else {
                        toast.error(response.data.msg);
                    }
                }
            }
        }

        return {
            profileForm,
            onProfileImageChange,
            onCoverImageChange,
            updateProfileInfo,
            coverpic,
            proficepic,
            selectedItem,
            options
        }
    }
})
</script>
<style scoped>
@media (max-width: 1200px) {
    .profile-image-upload {
        top: 23% !important;
        left: 32% !important;
    }
}

.vue-tel-input {
    height: 44px !important;
}

.profile-image-edit-wrapper {
    top: 64%;
    left: 5%;
}

.profile-image-upload {
    top: 30%;
    left: 36%;
}

.cover-image-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 45%;
    color: white;
    left: 49%
}

label .file[type='file'] {
    display: none;
}

label span {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 15px;
    border-radius: 4px;
    color: white;

}

.default-check {
    width: 25px !important;
    height: 25px !important;
}

.default-login {
    width: 100%;
}

.profile-edit-input {
    margin-top: 80px;

}

.input-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.profile-edit-input .input-container {
    width: 100%;
}

.input-container input,
select,
.date-of-birth input {
    width: 100%;
    height: 44px;
    border: 1px solid #A4A4A5;
    background-color: #333334;
    border-radius: 4px;
    color: #E4E4E5;
    padding-left: 10px;
    padding-right: 10px;
}

.input-container input:focus {
    border: 1px solid #A4A4A5;
    background-color: #333334;
    outline: none;
}

.form-label {
    margin-bottom: 4px !important;
}

select {
    cursor: pointer;
}

.multi-input {
    display: flex;
    width: 100%;
    gap: 10px;
}

.date-of-birth,
.gender {
    width: 50%
}

.profile-image {
    width: 180px;
    height: 180px;
    border: 5px solid #242425;
}

.cover-image {
    height: 280px;
    width: 100%;
    object-fit: cover;
}
</style>
