import axios from 'axios';
import config from '../../config/config';
import { tokenExpired } from '@/assets/js/utils';
const apiEndpoint = config.API_ENDPOINT;

const state = {
    events: [],
    eventDetailsData: {},
    hostUpcomingEvents: [],
    hostEventPagination: {},
};

const getters = {
    allEvents: (state) => state.events,
    eventDetailsData: (state) => state.eventDetailsData,
    getHostUpcomingEvents: (state) => state.hostUpcomingEvents,
    getHostEventPagination: (state) => state.hostEventPagination,
};

const actions = {
    async fetchEvents({ commit, rootGetters }, body) {
        const date = body && body.date ? body.date : null;
        const page = body && body.page ? body.page : 1;
        const perPage = body && body.perPage ? body.perPage : 10;
        const search = body && body.search ? body.search : null;
        const filter = body && body.filter ? body.filter : null;
        const sort = body && body.sort ? body.sort : null;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
        try {
            const response = await axios.get(`${apiEndpoint}/api/v1/get-event-list?host_id=${rootGetters['user/getUserId']}&date=${date}&page=${page}&perPage=${perPage}&search=${search}&filter=${filter}&sort=${sort}`);
            commit('setEvents', response.data.data);
            commit('setHostEventPagination', response.data.pagination);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            }
        }
    },
    async fetchHostUpcomingEvents({ commit, rootGetters }, hostId) {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.get(`${apiEndpoint}/api/v1/get-upcoming-event-list?host_id=${hostId}`);
            commit('setHostUpcomingEvents', response.data.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            }
        }
    },
    async addEvent({ commit, rootGetters }, eventData) {
        const formData = new FormData();
        formData.append('name', eventData.name);
        formData.append('start_time', eventData.start_time);
        formData.append('end_time', eventData.end_time);
        formData.append('address', eventData.address);
        formData.append('zip', eventData.zip);
        formData.append('city', eventData.city);
        formData.append('state', eventData.state);
        formData.append('country', eventData.country);
        formData.append('banner', eventData.banner);
        formData.append('host_id', eventData.hostId);

        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/store-event', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            commit('addEvent', response.data.data);
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async deleteEvent({ commit, rootGetters }, eventId) {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(`${apiEndpoint}/api/v1/delete-event`, { id: eventId });
            commit('removeEvent', eventId);
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async updateEvent({ commit, rootGetters }, updatedEvent) {
        const formData = new FormData();
        formData.append('id', updatedEvent.id);
        formData.append('name', updatedEvent.name);
        formData.append('start_time', updatedEvent.start_time);
        formData.append('end_time', updatedEvent.end_time);
        formData.append('address', updatedEvent.address);
        formData.append('zip', updatedEvent.zip);
        formData.append('city', updatedEvent.city);
        formData.append('state', updatedEvent.state);
        formData.append('country', updatedEvent.country);
        formData.append('banner', updatedEvent.banner);
        formData.append('host_id', updatedEvent.hostId);

        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/update-event', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            commit('updateEvent', response.data.data);
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async fetchEventDetails({ commit, rootGetters }, eventId) {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.get(apiEndpoint + `/api/v1/get-event-details?id=${eventId}`);
            commit('setEventDetailsData', response.data.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async markAsSongRequest({ commit, rootGetters }, body) {
        const formData = new FormData();
        formData.append('song_id', body.song_id);
        formData.append('event_id', body.event_id);
        formData.append('mark_as', body.mark_as);

        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/mark-as-song-request', formData);
            const requestBody = {
                song: response.data.data,
                markAs: body.mark_as,
                songId: body.song_id,
                eventId: body.event_id
            }
            commit('markAsSongRequest', requestBody);
            return response;
        } catch (error) {
            console.log(error)
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
};

const mutations = {
    setEvents: (state, events) => (state.events = events),
    setHostUpcomingEvents: (state, hostUpcomingEvents) => (state.hostUpcomingEvents = hostUpcomingEvents),
    addEvent: (state, newEvent) => {
        state.events.push(newEvent)
        state.hostUpcomingEvents.push(newEvent)
    },
    removeEvent: (state, eventId) => {
        state.events = state.events.filter((event) => event.id !== eventId);
    },
    updateEvent: (state, updatedEvent) => {
        const index = state.events.findIndex((event) => event.id === updatedEvent.id);
        if (index !== -1) {
            state.events.splice(index, 1, updatedEvent);
        }
        state.eventDetailsData = updatedEvent;
    },
    markAsSongRequest: (state, body) => {
        const updatedSong = body.song;
        if (body.markAs != 'delete') {
            const index = state.eventDetailsData.grouped_song_requests.findIndex((song) => song.song_id == updatedSong.song_id && song.event_id == updatedSong.event_id);
            if (index !== -1) {
                state.eventDetailsData.grouped_song_requests.splice(index, 1, updatedSong);
            }
        } else {
            state.eventDetailsData.grouped_song_requests = state.eventDetailsData.grouped_song_requests.filter((song) => song.song_id !== body.songId);
        }
    },
    setEventDetailsData(state, data) {
        state.eventDetailsData = data;
    },
    setHostEventPagination(state, hostEventPagination) {
        state.hostEventPagination = hostEventPagination;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
