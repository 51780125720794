<template>
    <input type="file" @change="handleFileChange" accept="image/*" />
    <button @click="crop">
        Crop
    </button>
    <cropper class="cropper" :src="img" :stencil-props="stencilProps" @change="change" ref="cropperRef" />

    <div v-if="croppedImage">
        <h3>Cropped Image Preview:</h3>
        <img :src="croppedImage" alt="Cropped Image Preview" />
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
    components: {
        Cropper,
    },
    setup() {
        const img = ref('');
        const croppedImage = ref(null);
        const coordinates = ref({
            width: 0,
            height: 0,
            left: 0,
            top: 0
        });
        const cropperRef = ref(null);

        const stencilProps = {
            // aspectRatio: 10 / 12
        };

        onMounted(() => {
            img.value = 'https://images.unsplash.com/photo-1600984575359-310ae7b6bdf2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80';
        });

        const change = ({ coordinates, canvas }) => {
            console.log(coordinates, canvas);
        };

        const crop = () => {
            const { coordinates, canvas, } = cropperRef.value.getResult();
            coordinates.value = coordinates;
            croppedImage.value = canvas.toDataURL();
            console.log(croppedImage.value);
        };

        const handleFileChange = (event) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    img.value = reader.result;
                };
                reader.readAsDataURL(file);
            }
        };

        return {
            change,
            img,
            crop,
            stencilProps,
            coordinates,
            croppedImage,
            cropperRef,
            handleFileChange
        };
    }
}
</script>

<style>
.cropper {
    height: 200px;
    width: 200px;
    background: #DDD;
}
</style>
