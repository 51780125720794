<template>
    <template v-if="!loading">
        <div class="d-flex justify-content-between align-items-center">
            <button @click="goBack" class="btn bg-neutral-800 text-neutral-25 ps-3 pe-3 pt-2 pb-2"><i
                    class="fa-solid fa-arrow-left"></i> <span class="ms-2">Go Back</span> </button>
            <template v-if="!loading">
                <button class="btn btn-primary text-neutral-25 ps-3 pe-3 pt-2 pb-2"
                    @click="openModal('eventEditModal')"><i class="fa-solid fa-file-pen"></i><span
                        class="ms-3">Edit</span></button>
                <EventEditForm :event="event" :updateEventValue="updateEventValue" />
            </template>
        </div>
        <div class="mt-3">
            <img class="cover-image position-relative rounded " :src="event?.banner_path || require('@/assets/util/images/no_event_banner.jpg')" alt="">
            <div class="container  mt-5 ">
                <div class="row ">
                    <div class=" col-12 col-md-12 col-lg-9">
                        <div class="d-flex justify-content-between  align-items-start">
                            <div>
                                <h3 class="text-neutral-25 event-title text-bold text-break">{{ event.name }}</h3>
                                <!-- <span class="badge rounded-pill bg-primary text-neutral-25">2 Events</span> -->
                            </div>
                            <div>
                                <button @click="openModal('qrcodeModal')" class="rounded share-qr text-neutral-800 font-weight-bold text-4 d-flex align-items-center gap-2"><i class="fa-solid fa-qrcode"></i> <span class="text-4 d-none d-lg-inline-block">SHARE</span> </button>
                            </div>
                        </div>
                        <div class="container p-0 mt-3">
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
                                    <div
                                        class="card-body bg-neutral-800 d-flex flex-column justify-content-between p-3 rounded">
                                        <div>
                                            <div
                                                class="card-date text-center text-primary bg-neutral-900 text-24 font-weight-bold rounded m-2">
                                                {{ event.start_date_month }}</div>
                                            <div class="mt-3">
                                                <h6 class="text-neutral-25 text-center">Event Date</h6>
                                                <p class="text-center"><span
                                                        class="text-neutral-25 text-3 text-neutral-200">{{
        event.formatted_start_time }} - </span> <span
                                                        class="text-neutral-25 text-3 text-neutral-200">{{
        event.formatted_end_time
    }}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
                                    <div
                                        class="card-body bg-neutral-800 d-flex flex-column justify-content-between p-3 rounded">
                                        <div>
                                            <div
                                                class="card-date text-center text-primary bg-neutral-900 text-24 font-weight-bold rounded m-2">
                                                <i class="fa-solid fa-location-dot"></i>
                                            </div>
                                            <div class="mt-3">
                                                <h6 class="text-neutral-25 text-center">Location</h6>
                                                <p class="text-center"><span
                                                        class="text-neutral-25 text-3 text-neutral-200 text-break">{{
            event.address || 'N/A' }}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
                                    <div
                                        class="card-body bg-neutral-800 d-flex flex-column justify-content-between p-3 rounded">
                                        <div>
                                            <div
                                                class="card-date text-center text-primary bg-neutral-900 text-24 font-weight-bold rounded m-2">
                                                <i class="fa-solid fa-qrcode"></i>
                                            </div>
                                            <div class="mt-3">
                                                <h6 class="text-neutral-25 text-center">Joined</h6>
                                                <p class="text-center"><span
                                                        class="text-neutral-25 text-20 text-neutral-200">{{
        event.enrolled_users_count }}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
                                    <div
                                        class="card-body bg-neutral-800 d-flex flex-column justify-content-between p-3 rounded">
                                        <div>
                                            <div
                                                class="card-date text-center text-primary bg-neutral-900 text-24 font-weight-bold rounded m-2">
                                                <i class="fa-solid fa-music"></i>
                                            </div>
                                            <div class="mt-3">
                                                <h6 class="text-neutral-25 text-center">Song Request</h6>
                                                <p class="text-center"><span
                                                        class="text-neutral-25 text-20 text-neutral-200">{{
        event.song_requests_count }}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-4">
                            <div class="d-flex justify-content-center">
                                <h6
                                    class="bg-primary ps-2 pt-1 pb-1 pe-2 text-neutral-25 text-4 text-bold tab-title song-name">
                                    Song Request</h6>
                            </div>
                            <div class="mt-3">
                                <div class="row">
                                    <template v-if="event.song_requests.length">
                                        <div v-for="(song, index) in event.grouped_song_requests" :key="index"
                                            class="col-6 col-sm-6  col-md-4 col-lg-4 col-xl-3 position-relative mb-3">
                                            <SongRequestCard :song="song" />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <NoData />
                                    </template>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-3">
                        <div class="row">
                            <div class="mb-3 col-12 col-md-6 col-lg-12">
                                <div class="rounded bg-neutral-800  ">
                                    <h2 class="text-neutral-25 text-20 pt-3 ps-2">Host</h2>
                                    <div>
                                        <img height="110"
                                            class="host-cover-image position-relative pt-2 ps-2 pe-2 rounded object-fit-cover"
                                            :src="event.host.cover_photo_path || require('@/assets/util/images/cover.jpg')"
                                            alt="">
                                        <div class="profile">
                                            <img class="host-profile-image rounded-circle position-absolute"
                                                :src="event.host.profile_image_path || require('@/assets/util/images/avatar.png')"
                                                alt="">
                                        </div>
                                    </div>
                                    <h5 class="text-neutral-25 text-20 text-center mt-5">{{ event?.host?.display_name }}
                                    </h5>
                                    <p class="text-neutral-300 p-2 text-center">
                                        {{ event?.host?.bio && event.host.bio.length > 100 ? event.host.bio.slice(0,
                                        100) +
                                        '...' : event?.host?.bio }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <QrcodeModal :src="event.qrcode_path" :code="event.code" :basePath="event.qrcode_base_path" :url="event.url"
            :type="'event'" />
    </template>
    <template v-else>
        <LoadingComponent />
    </template>
</template>

<script>
import { openModal } from '@/assets/js/utils';
import { useStore } from 'vuex';
import { ref, onMounted, inject } from 'vue';
import { useRoute } from 'vue-router';
import EventEditForm from './EventEditForm.vue';
import QrcodeModal from '@/components/Host/Event/QrcodeModal.vue';
import LoadingComponent from '@/components/Shared/LoadingComponent.vue';
import NoData from '@/components/Shared/NoData.vue';
import SongRequestCard from '@/components/Shared/SongRequestCard.vue';

export default {
    components: {
        EventEditForm,
        QrcodeModal,
        LoadingComponent,
        NoData,
        SongRequestCard
    },
    setup() {
        const router = inject('router');
        const route = useRoute();
        const store = useStore();
        const eventId = route.params.eventId;
        const event = ref({});
        const loading = ref(true);

        onMounted(async () => {
            await fetchEvent();
        });

        const fetchEvent = async () => {
            try {
                await store.dispatch('event/fetchEventDetails', eventId);
                event.value = store.getters['event/eventDetailsData'];
                loading.value = false;
            } catch (error) {
                console.error(error);
            }
        };

        const updateEventValue = (newEvent) => {
            event.value = newEvent;
        }

        const goBack = () => {
            router.go(-1);
        }

        return {
            openModal,
            event,
            loading,
            updateEventValue,
            goBack
        }
    }
}
</script>
<style scoped>
.profile {
    display: flex;
    align-items: center;
    justify-content: center;
}

.event-title {
    font-size: 32px;
    font-weight: bold;
}

.tab-title {
    border-radius: 100px;
}

.song-cover {
    width: 100%;
}

.song-info {
    top: 5%;
    left: 8%;
}

.request-by {
    bottom: 2%;
    left: 10%;
}

.song-name {
    font-weight: 600;
}

.card-body {
    height: 100%;
}

.dropdown-profile-image {
    top: 80px;
    left: 37%;
}

.host-profile-image {
    width: 80px;
    height: 80px;
}

.host-cover-image {
    width: 100%;
}

.view-profile {
    width: 94%;
}

@media (max-width: 992px) {
    .event-title {
        font-size: 22px;
        font-weight: bold;
    }

    .modal-content-wrapper {
        flex-direction: column !important;
        align-items: center;
        gap: 50px;
    }

    .border-content {
        display: none !important;
    }
}
</style>
