import store from "@/store";

export default function authMiddleware(to, from, next, role) {
    const isLoggedIn = store.getters['user/getAccessToken'] ? true : false;
    if (store.getters['user/getUserRole'] != 4) {
        store.dispatch('user/setRedirectRole', role);
        store.dispatch('user/setUserRole', role);
    }


    if (!isLoggedIn || (store.getters['user/getUserRole'] == 4 && (to.name != 'attendee.event.details' && to.name != 'profile.details'))) {
        store.dispatch('user/setRedirectPath', to.fullPath);
        store.dispatch('user/setAccessToken', null);
        if (to.query.qr == 'y') {
            next({ name: 'login', query: { qr: 'y' } });
        } else {
            next({ name: 'login' });
        }
    } else {
        next();
    }
}
