import Token from './Token.js';
import AppStorege from './AppStorege.js';

class User {
    responseAfterLogin(res) {
        const access_token = res.data.access_token;
        const userID = res.data.id;
        const userRole = res.data.role;
        const user = res.data.user;
        AppStorege.store(access_token, userID, userRole, user);

        // if (userRole == 4) {
        //     AppStorege.store(access_token, username, userID, userRole);
        // } else if (Token.isValid(access_token)) {
        //     AppStorege.store(access_token, username, userID, userRole);
        // }
    }

    hasToken() {
        const storedToken = AppStorege.getToken(); // Use AppStorege to retrieve token
        return storedToken ? Token.isValid(storedToken) : false; // Use AppStorege to retrieve and validate token
    }

    loggedIn() {
        return this.hasToken();
    }

    logout() {
        AppStorege.clear();
    }

    name() {
        if (this.loggedIn()) {
            return AppStorege.getUser(); // Use AppStorege to retrieve user
        }
    }

    id() {
        if (this.loggedIn()) {
            const payload = Token.payload(AppStorege.getToken()); // Use AppStorege to retrieve token
            return payload.sub;
        }
        return false;
    }
}

const UserInstance = new User(); // Create an instance of the class

export default UserInstance; // Export the instance, not the class itself
